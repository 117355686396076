const links = {
  market: 'https://dexscreener.com/solana/6uvxbzv2ulek5pcxjfrbeizqttkezyznjumsn6gtrdyt',
  coinMarketCap: 'https://coinmarketcap.com/',
  dex: 'https://dexscreener.com/solana/6uvxbzv2ulek5pcxjfrbeizqttkezyznjumsn6gtrdyt',
  tiktok: 'https://www.tiktok.com',
  telegram: 'https://t.me/Node_sol',
  ig: 'https://www.instagram.com',
  x: 'https://x.com/Node_Sol',
  CA: '2UqpumQPfmx8MMBe64zUKfiuUhc8NKQtzbTTD428pump'
};

export default links;
