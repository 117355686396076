import React from 'react';
import video1_1 from './assets/videos/grid/1_1.webm';
import video1_2 from './assets/videos/grid/1_2.webm';
import video1_3 from './assets/videos/grid/1_3.webm';
import video2_1 from './assets/videos/grid/2_1.webm';
import img2_2 from './assets/videos/grid/2_2.png';
import video2_3 from './assets/videos/grid/2_3.webm';
import video2_4 from './assets/videos/grid/2_4.webm';
import video3_1 from './assets/videos/grid/3_1.webm';
import video3_2 from './assets/videos/grid/3_2.webm';
import Socials from './components/Socials';
import { useState, useEffect } from 'react';


function App() {

  function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);
  
    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      media.addListener(listener);
      return () => media.removeListener(listener);
    }, [matches, query]);
  
    return matches;
  }

  // Media query to check if the screen is at least 768px wide (i.e., not mobile)
  const isDesktop = useMediaQuery('(min-width: 768px)');

  return (
    <div className="flex flex-col md:mb-56">
      {/* Render the grid area only on desktop screens */}
      {isDesktop && (
        <div className="flex-grow p-4 grid grid-cols-4 gap-4">
          {/* ROW 1 */}
          <div className="w-full">
            <video autoPlay loop muted playsInline className="max-w-full w-full h-full object-fit">
              <source src={video1_1} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-[130%] relative" style={{ transform: 'translate(0%, 10%)' }}>
            <video autoPlay loop muted playsInline className="max-w-full w-full h-full object-fit">
              <source src={video1_2} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-[140%] relative" style={{ transform: 'translate(0%, 10%)' }}>
            <video autoPlay loop muted playsInline className="max-w-full w-full h-full object-fit">
              <source src={video1_3} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div></div>

          {/* ROW 2 */}
          <div className="w-[150%] relative" style={{ transform: 'translate(0%, -30%)' }}>
            <video autoPlay loop muted playsInline className="w-[40rem] h-full object-fit">
              <source src={video2_1} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="h-[50%] relative fit z-50" style={{ transform: 'translate(40%, -10%)' }}>
            <img src={img2_2} alt="cool beans" className="max-w-full w-full object-fit z-50" />
          </div>
          <div className="w-[150%] relative" style={{ transform: 'translate(15%, 0%)' }}>
            <video autoPlay loop muted playsInline className="max-w-full w-full h-full object-fit">
              <source src={video2_3} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-full z-50 relative" style={{ transform: 'translate(0%, 50%)' }}>
            <video autoPlay loop muted playsInline className="max-w-full w-full h-full object-fit">
              <source src={video2_4} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>

          {/* ROW 3 */}
          <div className="w-[130%] relative" style={{ transform: 'translate(0%, -70%)' }}>
            <video autoPlay loop muted playsInline className="max-w-full w-full h-full object-fit">
              <source src={video3_1} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-[110%] relative" style={{ transform: 'translate(25%, -60%)' }}>
            <video autoPlay loop muted playsInline className="max-w-full w-full h-full object-fit">
              <source src={video3_2} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}

      {/* Flex column layout for smaller screens */}
      {!isDesktop && (
        <div className="flex flex-col w-full justify-center items-center overflow-hidden">
          <div className="w-[110%] -mb-12">
            <video autoPlay loop muted playsInline className="w-full h-auto object-fit">
              <source src={video1_2} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="w-full fit z-50">
            <img src={img2_2} alt="cool beans" className="w-full h-auto object-fit z-50" />
          </div>
          <div className="w-[150%]">
            <video autoPlay loop muted playsInline className="w-full h-auto object-fit">
              <source src={video3_2} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}

      {/* Socials area */}
      <div className="-mt-[10%] md:-mt-[15%]">
        <Socials />
      </div>
    </div>
  );
}

export default App;
