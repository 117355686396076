import React, { useState } from 'react';
import coinmarketcap from '../assets/icons/coinmarketcap.png';
import coinmarketcapOpen from '../assets/icons/coinmarketcap-open.png';
import dex from '../assets/icons/dex.png';
import dexOpen from '../assets/icons/dex-open.png';
import dextools from '../assets/icons/dextools.png';
import dextoolsOpen from '../assets/icons/dextools-open.png';
import telegram from '../assets/icons/telegram.png';
import telegramOpen from '../assets/icons/telegram-open.png';
import x from '../assets/icons/x.png';
import xOpen from '../assets/icons/x-open.png';

import caCopy from '../assets/videos/grid/ca.png';


import links from '../config/links';

const Socials = () => {
  // States to track hover for each icon
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const handleShareClick = () => {
    navigator.clipboard.writeText(links.CA)
      .then(() => {
        setShowNotification(true);
        setTimeout(() => setShowNotification(false), 3000);
      })
      .catch((err) => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <>
    <div

      className="mx-auto mt-12 w-full max-w-2xl sm:max-w-3xl 3xl:max-w-4xl p-3 flex justify-around items-center gap-1 sm:gap-4 3xl:gap-8"
    >
      {/* CoinMarketCap Icon */}
      <a
        href={links.coinMarketCap}
        target="_blank"
        rel="noopener noreferrer"
        className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center duration-300 hover:scale-125"
        onMouseEnter={() => setHoveredIcon('coinMarketCap')}
        onMouseLeave={() => setHoveredIcon(null)}
      >
        <img
          src={hoveredIcon === 'coinMarketCap' ? coinmarketcapOpen : coinmarketcap}
          alt="coinMarketCap"
          className="h-full w-full object-contain"
        />
      </a>

      {/* Dex Icon */}
      <a
        href={links.dex}
        target="_blank"
        rel="noopener noreferrer"
        className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center duration-300 hover:scale-125"
        onMouseEnter={() => setHoveredIcon('dex')}
        onMouseLeave={() => setHoveredIcon(null)}
      >
        <img
          src={hoveredIcon === 'dex' ? dexOpen : dex}
          alt="dex"
          className="h-full w-full object-contain"
        />
      </a>

      {/* Dextools Icon */}
      <a
        href={links.dextools}
        target="_blank"
        rel="noopener noreferrer"
        className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center duration-300 hover:scale-125"
        onMouseEnter={() => setHoveredIcon('dextools')}
        onMouseLeave={() => setHoveredIcon(null)}
      >
        <img
          src={hoveredIcon === 'dextools' ? dextoolsOpen : dextools}
          alt="dextools"
          className="h-full w-full object-contain"
        />
      </a>

      {/* Telegram Icon */}
      <a
        href={links.telegram}
        target="_blank"
        rel="noopener noreferrer"
        className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center duration-300 hover:scale-125"
        onMouseEnter={() => setHoveredIcon('telegram')}
        onMouseLeave={() => setHoveredIcon(null)}
      >
        <img
          src={hoveredIcon === 'telegram' ? telegramOpen : telegram}
          alt="telegram"
          className="h-full w-full object-contain"
        />
      </a>

      {/* X Icon */}
      <a
        href={links.x}
        target="_blank"
        rel="noopener noreferrer"
        className="w-16 h-16 sm:w-24 sm:h-24 3xl:w-32 3xl:h-32 rounded-full flex justify-center items-center duration-300 hover:scale-125"
        onMouseEnter={() => setHoveredIcon('x')}
        onMouseLeave={() => setHoveredIcon(null)}
      >
        <img
          src={hoveredIcon === 'x' ? xOpen : x}
          alt="x"
          className="h-full w-full object-contain"
        />
      </a>
    </div>

    <div className="mt-4 mx-auto mb-20 flex flex-col items-center justify-center relative">
  <img 
    src={caCopy} 
    className="cursor-pointer duration-300 hover:scale-110 " 
    onClick={handleShareClick} 
    alt="Contract Address" 
  />

  {showNotification && (
    <div className="absolute top-full mt-2 left-1/2 transform -translate-x-1/2 text-center w-96 3xl:w-[40rem] 3xl:text-3xl bg-[#00ff00] text-black py-2 px-4 rounded-md text-sm font-normal">
      CA copied to clipboard!
    </div>
  )}
</div>

    </>
  );
};

export default Socials;
